<template>
  <div class="ModelsReason" ref="label">
    <div>Tipo:</div>
    <div class="ModelsReason__wrapper containerRow">
      <p v-if="!isEdit" bold>{{ getTypeDecision(post.typeDecision) }}</p>
      <Select class="selectWaiverEnd selectCustomize" name="typeDecision" :options="optionsSelect" :elementSelect="post.typeDecision" ref="selectc" v-else />
      <div class="containerRow" v-if="hasEdit">
        <button :class="['buttonSmall', isEdit ? 'save' : 'edit']" @click.prevent="onClick">
          <iconic :name="!isEdit ? 'edit' : 'save'" />
        </button>
        <button class="buttonSmall cancel" @click.prevent="isEdit = false" v-if="isEdit">
          <iconic name="cancel" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    components: {
      Select: () => import("@/components/inputs/Select.vue"),
      iconic: () => import("@/plugins/iconic/iconic.vue"),
    },
    props: { post: { type: Object }, hasEdit: { type: Boolean, default: true } },
    data() {
      return {
        isEdit: false,
        cfilter: this.$route.query.status ? this.$route.query.status : this.$route.query.filter,
      };
    },
    computed: {
      optionsSelect: function () {
        let reason;
        if (this.cfilter === "waiver") reason = this.$global.dictionary.waiverReason;
        else if (this.cfilter === "endContract") reason = this.$global.dictionary.endContractReason;
        return Object.keys(reason).map((key) => {
          return { value: key, text: reason[key] };
        });
      },
    },
    methods: {
      getTypeDecision: function (type) {
        if (this.cfilter === "waiver") return this.$global.dictionary.waiverReason[type] || "Sin definir";
        else if (this.cfilter === "endContract") return this.$global.dictionary.endContractReason[type] || "Sin definir";
      },
      onClick: async function () {
        if (this.isEdit) {
          const _this = this;
          const select = this.$refs.selectc?.$el;
          const _host = `${process.env.VUE_APP_API_HOST}admin/models/updatemodel`;
          const _data = { modelId: this.post._id, typeDecision: select.value };
          this.dataSending();
          await this.$api
            .post(_host, _data)
            .then(function (response) {
              if (response.data) {
                _this.dataSuccess(response.data);
                _this.$emit("success", response.data);
              } else {
                _this.dataError(response.data.message);
              }
            })
            .catch(function (error) {
              _this.dataError(error);
            });
        }
        this.isEdit = !this.isEdit;
      },
      dataSending: function () {
        const label = this.$refs.label;
        label ? label.classList.add("loading") : false;
      },
      dataSent: function () {
        const label = this.$refs.label;
        label ? label.classList.remove("loading") : false;
      },
      dataError: function (message) {
        this.dataSent();
        this.$swal.fire({
          title: "Error",
          html: message ? message : "Error desconocido",
          icon: "error",
          confirmButtonText: "Revisar",
          timerProgressBar: true,
          confirmButtonColor: "#bd0909",
        });
      },
      dataSuccess: function (data) {
        this.dataSent();
      },
    },
  };
</script>

<style lang="scss">
  .ModelsReason {
    width: 100%;
    &__wrapper {
      width: 100%;
    }
    @media screen and (min-width: $tablet_width) {
      @include Flex(row, flex-start, center);
      gap: 5px;
      position: relative;
    }
  }
  .selectWaiverEnd {
    width: 100%;
  }
  .containerRow {
    @include Flex(row, flex-start, center);
    gap: 5px;
    position: relative;
  }
  .buttonSmall {
    @include ButtonStyle();
    @include Flex(row);
    width: 25px;
    height: 25px;
    padding: 0;
    border-radius: 4px;
    background-color: $primary_color;
    cursor: pointer;
    &.edit {
      padding: $mpadding/3;
      font-size: 0.9em;
      i {
        position: relative;
        left: 1px;
        top: -1px;
      }
    }
    &.save,
    &.cancel {
      padding: $mpadding/3.5;
    }
    &.save {
      font-size: 17px;
      i {
        position: relative;
        top: -1px;
      }
    }
    &.cancel {
      font-size: 6.5px;
    }
  }
</style>
